import React from "react";
import Layout from "../components/layout";
import Img from "gatsby-image";
import SEO from "../components/seo";
import styled from 'styled-components';

export default ({ data }) => {
  return (
    <Layout>
      <SEO title="About" />
      <MainContent>
      <Headline>About Me</Headline>
          <MainContentImage><Img fluid={data.contentfulTopIntroduction.image.fluid} /></MainContentImage>
          <MainContentText>{data.contentfulTopIntroduction.introduction.introduction}</MainContentText>
      </MainContent>
    </Layout>
  )
};

const MainContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 980px;
    height: 94vh;
    margin: 0 auto;

    @media (max-width: 768px) {
      height: 78vh;
    }
`

const MainContentImage = styled.div`
  width: 20%;

  & img {
    object-fit:cover;
    border-radius:50%;
  }
`

const Headline = styled.h1`
  color: initial;
  font-size: 3rem;
  font-weight: 100;
  text-align: center;
  margin: 1rem 0;
`

const MainContentText = styled.p`
  color: initial;
  font-size: 0.8rem;
  font-weight: 100;
  text-align: center;
  padding: 1rem;
  margin: 2rem 0 1.2rem;
  white-space: pre-wrap;
  line-height: 2.2;
`

export const query = graphql`
  {
    contentfulTopIntroduction {
      introduction {
        introduction
      }
      image {
        fluid {
            ...GatsbyContentfulFluid_tracedSVG
        }
      }
    }
  }
`
